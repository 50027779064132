@import '@/assets/styles/variables.scss';

.categories-list {
	gap: 20px;
	display: flex;
	max-height: 50vh;
	overflow-y: auto;
	flex-direction: column;
	&__card {
		width: 98%;
		height: 52px;
		display: flex;
		padding: 0 15px;
		font-size: 1.5rem;
		border-radius: 10px;
		align-items: center;
		background-color: #FFFFFF;
		justify-content: space-between;
		&--active {
			color: $color-text;
		}
	}
}