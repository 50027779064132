.categories-view {
	&__center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__title {
		margin: 30px 0;
		color: #FFFFFF;
		font-size: 1.5rem;
		text-align: center;
	}
}