@import '@/assets/styles/variables.scss';

.challenge-default {
	&__card {
		font-size: 1.4rem;
		padding: 14px 10px;
		border-radius: 10px;
		color: $color-accent;
		background-color: #FFFFFF;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}

	&__title {
		color: #FFFFFF;
		margin: 28px 0;
		font-size: 1.5rem;
		text-align: center;
	}

	&__body {
		font-size: 1.5rem;
	}
}