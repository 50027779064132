@import './assets/styles/variables.scss';

@font-face {
  font-family: 'Dela Gothic One';
  src: local('Dela Gothic One'), url(./assets/fonts/DelaGothicOne-Regular.woff2) format('woff2');
}

.drink-ring {
  padding: 15px;
  min-height: 100vh;
  background-color: $color-bg;
  font-family: 'Dela Gothic One', cursive;

  &__title {
    font-size: 30px;
    color: $color-text;
    text-align: center;
  }
}

// General
* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
  --color-bg: $color-bg;
  --color-accent: $color-accent;
  --color-text: #D2990D;
  --color-text-light: $color-text-light;
  --color-text-dark: $color-text-dark;
}

.color-bg {
  background-color: $color-bg;
}

.color-accent {
  background-color: $color-accent;
}

.color-text {
  color: $color-text;
}

.color-text-light {
  color: $color-text-light;
}

.color-text-dark {
  color: $color-text-dark;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-accent;
}