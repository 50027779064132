// @import '../../variables.scss';
@import '@/assets/styles/variables.scss';

.wheel-view {
	&__selected {
		inset: 0;
		width: 100%;
		opacity: 1;
		left: -100%;
		display: flex;
		font-size: 2rem;
		color:$color-accent;
		position: absolute;
		align-items: center;
		justify-content: center;
		background-color: $color-text;
		transition: all 0.5s ease-in-out;

		&--active {
			left: 0;
			opacity: 1;
			z-index: 5;
			width: 100%;
		}
	}

	&__wheel {
		margin-top: 30px;
		margin-left: 15px;
		transition: all 0.5s ease-in-out;
		&--hidden {
			opacity: 0;
		}
	}
}