@import '@/assets/styles/variables.scss';

.add-player-form {
	margin-top: 43px;
	&__wrapper {
		gap: 15px;
		display: flex;
		justify-content: space-between;
	}
	&__input.form-control, &__input.form-control:focus {
		height: 52px;
		color: $color-text;
		border-top-right-radius: 0;
		border: 0 solid transparent;
		border-bottom-right-radius: 0;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
	&__btn.btn {
		height: 52px;
		border-top-left-radius: 0;
		background-color: #FFFFFF;
		border-bottom-left-radius: 0;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}

	&__divider {
		width: 100%;
		height: 1px;
		border-top: 1px dashed  $color-text;
	}
}

.add-player-form__input.form-control::placeholder {
	color: $color-text;
}