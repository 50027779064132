.players-list {
	gap: 20px;
	display: flex;
	max-height: 45vh;
	overflow-y: auto;
	flex-direction: column;
	&__item {
		width: 98%;
		height: 52px;
		display: flex;
		font-size: 1.5rem;
		align-items: center;
		border-radius: 10px;
		background-color: #FFFFFF;
		justify-content: space-between;
	}
	&__btn-wrapper {
		border-left: 1px solid #000000;
	}
	&__name-wrapper {
		padding-left: 16px;
	}
}