@import '@/assets/styles/variables.scss';

.the-button {
	left: 50%;
	height: 64px;
	width: 250px;
	bottom: 18px;
	margin: 0 auto;
	font-size: 32px;
	max-width: 100%;
	position: fixed;
	color: #FFFFFF;
	border-radius: 10px;
	transform: translateX(-50%);
	border: 0 solid transparent;
	background-color: $color-accent;
	transition: all 0.3s ease-in-out;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	&--disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}